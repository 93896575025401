import React, { ReactNode, useEffect, useState } from 'react'
import Hr from '../../Hr/Hr'
import * as IntroStyles from './Intro.module.scss'
import * as LeaderStyles from '../../Leader/Leader.module.scss'

interface IntroProps {
  children: ReactNode
  removeMargin?: boolean
  removeHr?: boolean
}

const Intro = ({ children, removeMargin, removeHr }: IntroProps) => {
  const [margin, setMargin] = useState<boolean>(false)

  useEffect(() => {
    if (removeMargin) {
      setMargin(true)
    }
  }, [removeMargin])
  return (
    <div className={IntroStyles.Base}>
      <div
        className={`${LeaderStyles.InnerWrapper} ${
          margin && IntroStyles.NoMargin
        }`}
      >
        {children}
      </div>
      {!removeHr && <Hr />}
      {/* <Hr /> */}
    </div>
  )
}

export default Intro
