// extracted by mini-css-extract-plugin
export var ActiveButton = "Leader-module--ActiveButton--60e0c";
export var Assets = "Leader-module--Assets--84192";
export var AssetsImage = "Leader-module--AssetsImage--fd4b5";
export var AssetsSquare = "Leader-module--AssetsSquare--f84fb";
export var AssetsText = "Leader-module--AssetsText--87054";
export var Awards = "Leader-module--Awards--33494";
export var AwardsContent = "Leader-module--AwardsContent--281f5";
export var AwardsLeaderImage = "Leader-module--AwardsLeaderImage--d6941";
export var AwardsTideCTA = "Leader-module--AwardsTideCTA--56072";
export var AwardsVariant = "Leader-module--AwardsVariant--7937b";
export var Button = "Leader-module--Button--ff03c";
export var DoubleSponsor = "Leader-module--DoubleSponsor--9a83b";
export var Error = "Leader-module--Error--d643e";
export var FinalistToggleButtons = "Leader-module--FinalistToggleButtons--aba1b";
export var InactiveButton = "Leader-module--InactiveButton--26ccd";
export var InnerWrapper = "Leader-module--InnerWrapper--9bc64";
export var LayoutSpacer = "Leader-module--LayoutSpacer--fad8c";
export var LeaderImg = "Leader-module--LeaderImg--47414";
export var MainBadge = "Leader-module--MainBadge--3a17d";
export var MuddyBadgeVideo = "Leader-module--MuddyBadgeVideo--4475a";
export var MuddyVideo = "Leader-module--MuddyVideo--dc932";
export var NationalHeaderAwards = "Leader-module--NationalHeaderAwards--5b57f";
export var NationalSponsor = "Leader-module--NationalSponsor--c97ce";
export var PasswordToggle = "Leader-module--PasswordToggle--abb5d";
export var RegionalSponsor = "Leader-module--RegionalSponsor--76216";
export var SponsorSubtitle = "Leader-module--SponsorSubtitle--ad51c";
export var SponsorTitle = "Leader-module--SponsorTitle--bab01";
export var SubNavWrapper = "Leader-module--SubNavWrapper--47d93";
export var Wrapper = "Leader-module--Wrapper--4abe4";
export var YouTubeEmbed = "Leader-module--YouTubeEmbed--5fb85";
export var nationalOscillate = "Leader-module--nationalOscillate--7a704";
export var regionalOscillate = "Leader-module--regionalOscillate--9ae05";