// eslint-disable-next-line node/no-extraneous-import
import { gql } from '@apollo/client'

const LogInMutation = gql`
  mutation LoginUser($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      authToken
      user {
        id
        name
        jwtRefreshToken
        email
      }
    }
    getSaves {
      id
    }
  }
`

export interface ILogInMutationData {
  login: {
    authToken: string
    user: {
      id: string
      name: string
      jwtRefreshToken: string
      email: string
    },
  },
  getSaves: {
    id: string;
  }[]
}

export interface ILogInMutationVariables {
  username: string
  password: string
}

export default LogInMutation
