import React, { useState, useReducer, ChangeEvent, useContext } from 'react'
import { Link, navigate } from 'gatsby'
import * as SignInStyles from './SignIn.module.scss'
import LogInMutation, {
  type ILogInMutationData,
  type ILogInMutationVariables
} from '../../../graphql/queries/LogInMutation'
// eslint-disable-next-line node/no-extraneous-import
import { useMutation } from '@apollo/client'
import { LoginContext } from '../../../lib/utils'
import DebrettsCTA from '../../../components/BestSchoolsAwards/DebrettsCTA/DebrettsCTA'

const emailIsValid = (email: string) => {
  return /\S+@\S+\.\S+/.test(email)
}

const friendlyErrors: Record<string, string> = {
  invalid_username:
    'Sorry, your email address and password combination is not correct',
  invalid_email:
    'Sorry, your email address and password combination is not correct',
  incorrect_password: 'Sorry, your password is not correct'
}

interface Props {
  redirect?: boolean
  schoolAwards?: boolean
}

export const SignInForm = ({ redirect = true, schoolAwards }: Props) => {
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [formValues, setFormValues] = useReducer(
    (currentValues, newValues) => ({ ...currentValues, ...newValues }),
    []
  )
  const formFields = ['username', 'password']
  const [logIn, { loading: logInLoading, error: logInError }] = useMutation<
    ILogInMutationData,
    ILogInMutationVariables
  >(LogInMutation)
  const { usr, setUsr } = useContext(LoginContext)

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSubmitted(false)
    const { name, value } = event.target

    setFormValues({ [name]: value })
  }

  const handleFormSubmission = () => {
    logIn({
      variables: {
        username: formValues.username,
        password: formValues.password
      }
    })
      .then(response => {
        const authToken = response.data?.login.authToken
        const usrDetails = {
          ...response.data?.login.user,
          saves: response.data?.getSaves.map(i => i.id)
        }
        if (authToken) {
          localStorage.setItem('token', authToken)
          localStorage.setItem('musr', JSON.stringify(usrDetails))
          setSubmitted(true)
          usrDetails && setUsr && setUsr({ viewer: usrDetails })

          if (redirect) {
            let redirectUrl
            if (typeof window !== 'undefined') {
              // eslint-disable-next-line node/no-unsupported-features/node-builtins
              const queryString = new URLSearchParams(window.location.search)
              redirectUrl = queryString.get('return')
            }
            navigate(redirectUrl ?? '/dashboard/')
          }
        }
      })
      .catch(e => console.log(e))
  }

  const isValid = () => {
    const isEmailValid = formValues.email
      ? emailIsValid(formValues.email)
      : true
    const isSchoolAwardsValid =
      schoolAwards === true ? ('debretts' in formValues ? true : false) : true
    const allFieldsFilled = formFields.every(
      required => formValues[required] && formValues[required].length > 0
    )

    return isEmailValid && isSchoolAwardsValid && allFieldsFilled
  }

  return (
    <div className={SignInStyles.Wrapper}>
      <form className={SignInStyles.Form}>
        <p>
          <label htmlFor="username">
            {schoolAwards ? `Email address` : `Username or email address`}
          </label>
          <input
            type={`email`}
            id="username"
            name="username"
            value={formValues.username || ''}
            onChange={handleInputChange}
          />
        </p>
        <p>
          <label htmlFor="password">Password</label>
          <input
            type={`password`}
            id="password"
            name="password"
            value={formValues.password || ''}
            onChange={handleInputChange}
          />
        </p>
        {schoolAwards && (
          <DebrettsCTA
            setFormValues={setFormValues}
            email={usr?.viewer?.email ?? ''}
            submit={submitted && usr?.viewer?.email ? true : false}
          />
        )}
        <p className={SignInStyles.ButtonRow}>
          <button
            type="button"
            onClick={() => handleFormSubmission()}
            disabled={!isValid()}
            // disabled={submitted || isValid()}
          >
            {logInLoading ? <>Submitting</> : 'Sign In'}
          </button>
          {logInError && (
            <span className={SignInStyles.Alert}>
              {friendlyErrors[logInError.message]}
            </span>
          )}
          {submitted && (
            <span className={SignInStyles.Alert}>{`${'Now redirecting'}`}</span>
          )}
        </p>
        <p className={`${SignInStyles.SmallRow}`}>
          <small className={`${logInError && SignInStyles.TopSpacer}`}>
            Forgot your password?{' '}
            <Link to={`/forgot-password`}>Reset it here</Link>.
          </small>
        </p>
      </form>
    </div>
  )
}

export default SignInForm
