import React, { Dispatch, useEffect, useState } from 'react'
import { signupToDebretts } from '../../../api/best-school-awards/debretts'
import * as ContentStyles from './../Content/Content.module.scss'

interface Props {
  email: string
  submit: boolean
  setFormValues: Dispatch<any>
}

const DebrettsCTA = ({ email, submit, setFormValues }: Props) => {
  const [optingIn, setOptingIn] = useState<boolean>()
  const signup = () => {
    signupToDebretts(email)
  }

  useEffect(() => {
    if (submit && optingIn === true) {
      signup()
    }
  }, [submit])

  useEffect(() => {
    if (optingIn !== undefined) {
      setFormValues({
        debretts: optingIn
      })
    }
  }, [optingIn])

  return (
    <div className={ContentStyles.DebrettsCTA}>
      <p>
        <strong>
          Would you like to be sent news from Debrett&apos;s Education?
          {` `}
          <span style={{ color: 'rgb(231, 44, 94)' }}>*</span>
        </strong>
      </p>
      <div className={ContentStyles.InputGroup}>
        <input
          type="radio"
          id={'debretts-cta-yes'}
          name={`debretts`}
          value={`true`}
          onChange={() => setOptingIn(true)}
        />
        <label htmlFor="debretts-cta-yes">Yes</label>
        <input
          type="radio"
          id={'debretts-cta-no'}
          name={`debretts`}
          value={`false`}
          onChange={() => setOptingIn(false)}
        />
        <label htmlFor="debretts-cta-no">No</label>
      </div>
    </div>
  )
}

export default DebrettsCTA
