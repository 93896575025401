import * as React from 'react'
import Header from '../components/Header/Header'
import SignInForm from '../components/layoutComponents/signIn/SignIn'
import Footer from '../components/Footer/Footer'
import Intro from '../components/organisms/intro/Intro'
import { Helmet } from 'react-helmet'

const SignUp = () => {
  return (
    <>
      <Helmet>
        <title>Log In | Muddy Stilettos</title>
      </Helmet>
      <Header />
      <Intro>
        <h1>Log In</h1>
        <p></p>
      </Intro>
      <SignInForm />
      <Footer />
    </>
  )
}

export default SignUp
